import { Injectable } from "@angular/core";
import { Observable, from, of } from "rxjs";
import { map, share } from "rxjs/operators";
import { Environment } from "src/environments/environment";

@Injectable()
export class SettingsService 
{
    private settings: Environment;
    private settings$: Observable<Environment>;

    constructor()
    {
        this.settings$ = from(fetch('./assets/environment/settings.json').then(resp => resp.json())).pipe(
            map(response => {
                return <Environment>response;
            })
        );
    }

    public getSettings(): Observable<Environment>
    {
        if (!!this.settings) 
        {
            return of(this.settings);
        }   

        return this.settings$.pipe(
            share()
        );
    }
}