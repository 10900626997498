import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AlertModule } from 'ngx-bootstrap/alert';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingIndicatorInterceptor } from './services/loading-indicator.interceptor';
import { LoadingIndicatorService } from './services/loading-indicator.service';
import { AdalService } from 'adal-angular4';
import { SettingsService } from './services/settings.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrandService } from './services/brand.service';

const initSettings = (settingsService: SettingsService) => {
  return () => settingsService.getSettings().toPromise();
}

const initBrandConfig = (brandService: BrandService) => {
  return () => {
    brandService.setBrandSpecificConfiguration();
    return Promise.resolve();
  }
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CollapseModule.forRoot(),
    TypeaheadModule.forRoot(),
    AlertModule.forRoot(),
    FontAwesomeModule
  ],
  providers: [
    { 
      provide: APP_INITIALIZER,
      useFactory: initSettings,
      deps: [SettingsService],
      multi: true
    },
    { 
      provide: APP_INITIALIZER,
      useFactory: initBrandConfig,
      deps: [BrandService],
      multi: true
    },
    SettingsService,
    BrandService,
    AdalService,
    LoadingIndicatorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingIndicatorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]

})
export class AppModule { }
