import { Injectable } from "@angular/core";

@Injectable()
export class BrandService {
    public apiBrandName: string;
    public displayBrandName: string;
    public brand: string;

    constructor() {

    }

    /**
     * Add brand specific link and meta tags based on the search param 'brand' or hostname in the url
     * Add brand specific class to body tag
     * Set display brand name and brand in environment constant
     */
    public setBrandSpecificConfiguration(): void {
        // get the brand from the hostname and the search param 'brand'
        const hostname = window.location.hostname.toLowerCase();
        const url = new URL(window.location.href);
        const searchParamBrandName = url.searchParams.get('brand');

        // default brandname to pulte
        let brandName = 'pulte';

        // set brand name based on search params if they exist. otherwise, use hostname
        if (searchParamBrandName) {
            brandName = this.getBrandName(searchParamBrandName);
        } else {
            brandName = this.getBrandName(hostname);
        }

        // set environment brand display name
        this.setEnvironmentBrand(brandName);

        // add the brand-{brand} class to the body element where {brand} is the brand name
        document.getElementsByTagName('body')[0].className += 'brand-' + brandName;

        // array of link tags
        const linkTags = [
            {
                rel: 'apple-touch-icon',
                sizes: '180x180',
                href: '/assets/' + brandName + '/favicon/apple-touch-icon-180x180.png'
            },
            {
                rel: 'apple-touch-icon',
                sizes: '144x144',
                href: '/assets/' + brandName + '/favicon/apple-touch-icon-144x144.png'
            },
            {
                rel: 'icon',
                type: 'image/png',
                sizes: '32x32',
                href: '/assets/' + brandName + '/favicon/favicon-32x32.png'
            },
            {
                rel: 'shortcut icon',
                href: '/assets/' + brandName + '/favicon/favicon.ico'
            },
            {
                rel: 'stylesheet'
            }
        ];

        // array of meta tags
        const metaTags = [
            {
                name: 'msapplication-TileImage',
                content: '/assets/' + brandName + '/favicon/mstile-144x144.png'
            }
        ];

        const head = document.getElementsByTagName('head')[0];

        // add link tags to head
        for (const linkTag of linkTags) {
            const link = document.createElement('link');

            if (linkTag.rel) {
                link.rel = linkTag.rel;
            }
            if (linkTag.type) {
                link.type = linkTag.type;
            }
            if (linkTag.sizes) {
                link.sizes.value = linkTag.sizes;
            }
            if (linkTag.href) {
                link.href = linkTag.href;
            }

            head.appendChild(link);
        }

        // add meta tags to head
        for (const metaTag of metaTags) {
            const meta = document.createElement('meta');

            if (metaTag.name) {
                meta.name = metaTag.name;
            }
            if (metaTag.content) {
                meta.content = metaTag.content;
            }

            head.appendChild(meta);
        }
    }

    // set brand name that will be used for display across the site in environment constant
    private setEnvironmentBrand(brandName: string): void {
        let displayBrandName: string;

        switch (brandName) {
            case 'pulte':
                displayBrandName = 'Pulte';
                break;
            case 'delwebb':
                displayBrandName = 'Del Webb';
                break;
            case 'centex':
                displayBrandName = 'Centex';
                break;
            case 'divosta':
                displayBrandName = 'DiVosta';
                break;
            case 'jw':
                displayBrandName = 'John Wieland';
                break;
            case 'americanwest' || 'americanwesthomes':
                displayBrandName = 'American West';
                break;
            default:
                displayBrandName = 'Pulte';
                break;
        }

        let apiBrandName: string = displayBrandName;

        switch (apiBrandName) {
            case 'John Wieland':
                apiBrandName = 'JW';
                break;
            case 'American West':
                apiBrandName = 'AmericanWest';
                break;
            default:
                break;
        }

        this.apiBrandName = apiBrandName;
        this.displayBrandName = displayBrandName;
        this.brand = brandName;
    }

    private getBrandName(value: string): string {
        let brandName: string;

        if (value === 'pulte' || value.includes('pulte')) {
            brandName = 'pulte';
        } else if (value === 'delwebb' || value.includes('delwebb')) {
            brandName = 'delwebb';
        } else if (value === 'centex' || value.includes('centex')) {
            brandName = 'centex';
        } else if (value === 'divosta' || value.includes('divosta')) {
            brandName = 'divosta';
        } else if (value === 'jw' || value.includes('jwhomes')) {
            brandName = 'jw';
        } else if (value === 'americanwest' || value.includes('americanwesthomes')) {
            brandName = 'americanwest';
        } else {
            brandName = 'pulte';
        }

        return brandName;
    }

}