import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppInsightsService } from './services/app-insights.service';
import { LoadingIndicatorService } from './services/loading-indicator.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {

  loading = false;
  private unsubscribe$ = new Subject();

  constructor(private loadingIndicatorService: LoadingIndicatorService, private cdr: ChangeDetectorRef,
              private router: Router, private appInsightsService: AppInsightsService) {}

  ngOnInit() {
    // log page views to app insights
    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.appInsightsService.logPageView(event.urlAfterRedirects, event.urlAfterRedirects);
      }
    });
  }

  ngAfterViewChecked() {
    this.loadingIndicatorService.loading
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(loading => {
        this.loading = loading;
      });
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
