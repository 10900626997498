import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoadingIndicatorService {

  loading = new Subject<boolean>();

  // Stores all currently active requests
  private requests: HttpRequest<any>[] = [];

  // Adds request to the storage and notifies observers
  onStarted(req: HttpRequest<any>): void {
    this.loading.next(true);
    this.requests.push(req);
  }

  // Removes request from the storage and notifies observers
  onFinished(req: HttpRequest<any>): void {
    const index = this.requests.indexOf(req);
    if (index !== -1) {
      this.requests.splice(index, 1);
    }
    if (this.requests.length <= 0) {
      this.loading.next(false);
    }
  }
}
