import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/customer-registration', pathMatch: 'full' },
  { path: 'customer-registration', loadChildren: () => import('./customer-registration/customer-registration.module').then(m => m.CustomerRegistrationModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
